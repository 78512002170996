import React from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'

const FooterGroup = styled.div`
    background: radial-gradient(circle at 20% 20%,#000 25%,#000 100%);
    padding: 70px 0;
    display: grid;
    grid-gap: 20px;
`
const Text = styled.p`
    font-size: 24px;
    font-weight: 600;
    color: #486791;
    max-width: 500px;
    margin: 0 auto;
`
const Button = styled.button`
    background: linear-gradient(102.24deg, #9B51E0 0%, #3436E7 100%);
    box-shadow: 0px 10px 20px rgba(101, 41, 255, 0.15);
    border-radius: 30px;
    color: white;
    border: none;
    padding: 16px 60px;
    font-weight: 600;
    font-size: 24px;
    justify-self: center;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    &:hover {
        box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
        transform: translateY(-3px);
    }
`
const LinkGroup = styled.div`
    width: 500px;
    margin: 50px auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    a {
        transition: 0.8s;
    }
    a:hover {
        color: black;
    }
`

const Copyright = styled.div`
    color: #486791;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 20px;
    font-size:12px;
    text-align: center;

    a{
      font-size:10px;
      text-transform:uppercase;
    }
    
`

const Social =styled.div`
  display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin:  0;
    justify-items: center;
    max-width:300px;
    margin:0 auto;
  
  
  @media (max-width: 640px) {
      grid-template-columns: repeat(2, 1fr);
    }
`

const FooterLinks =styled.div`
  display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 50px;
    margin:  0 auto;
    justify-items: center;
    max-width:100%;
max-width:800px;  
padding-bottom:50px; 

  
  
  
  @media (max-width: 768px) {
     display:none;
     
    }

    p{
      color:#5334F5;
      padding:15px 0 0 0;
    
      margin:0px;
    }

    h6{
      color:rgb(148, 164, 186);
      padding:0px;
      margin:0px;
      font-size:18px;
    }
`

const FooterLinksMobile =styled.div`
  display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin:  0 auto;
    justify-items: center;
   font-size:14px;
letter-spacing:2px;
text-transform: uppercase;
  color:#777777;
  
  
  a{
      font-weight:400;
  }
  
  @media (min-width: 640px) {
     display:none;
     
    }
`

const Footer = ({data, children}) => (
  
    <FooterGroup>
      <Text></Text>

         


     
     <Social>
         
     <a href="https://www.instagram.com/timas_flavours" rel="nofollow" target="_blank"> <img src='/images/wbm-instagram.svg' width="30" /></a>
     <a href="https://www.facebook.com/timasflavours/" rel="nofollow" target="_blank"> <img src='/images/wbm-facebook.svg' width="30" /></a>
         

         
          </Social>
      <Copyright>© 2020 Timas Flavours. <br></br><a href ="https://www.websitesbymo.com">Web Design By Websites By Mo</a></Copyright>
    </FooterGroup>
)

export default Footer