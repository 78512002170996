// NavbarLinks.js

import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const NavItem = styled(Link)`
  text-decoration: none;
  color: #fff;
  display: grid;
  grid-template-columns: repeat(5, auto);

  white-space: nowrap;
  margin: 0 3vw;
  transition: all 200ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: #e5223c;
    height: 1px;
    transition: all 0.4s ease-in;

  }

  :hover {
    color: #e5223c;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
     padding: 20px 0;
    font-size: 1.5rem;
    margin-top:10px;

  }
`

const Button = styled.button`
margin: 0 20px;
padding: 8px 20px;
font-size: 20px;
background: #56CCF2;
border: none;
font-weight: 700;
border-radius: 10px;
outline: none;
cursor: pointer;
transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
:hover{
  background: white;
  box-shadow: 0 10px 20px rgba(0,0,0, 0.25);
  transform: translateY(-3px);
}
`

const NavbarLinks = () => {
  return (
    <>
          <NavItem to="/menu">Menu</NavItem>

      <NavItem to="/about">About</NavItem>
      <NavItem to="/contact">Contact</NavItem>
    </>
  )
}

export default NavbarLinks