import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
const HEAD = ({ title, description, keywords, image, article }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    defaultKeywords,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata
  const head = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    keywords: keywords || defaultKeywords,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }
  return (
    <Helmet title={head.title} titleTemplate={titleTemplate}>
    <meta name ="google-site-verification" content="CIzimdAFMH2efD4HRQ8GDJZTmiiy57Mynk026Ek9cFc" />
      <meta name="description" content={head.description} />
      <meta name="keywords" content={head.keywords} />
      <meta name="image" content={head.image} />
      {head.url && <meta property="og:url" content={head.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {head.title && <meta property="og:title" content={head.title} />}
      {head.description && (
        <meta property="og:description" content={head.description} />
      )}
      {head.image && <meta property="og:image" content={head.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {head.title && <meta name="twitter:title" content={head.title} />}
      {head.description && (
        <meta name="twitter:description" content={head.description} />
      )}
      {head.image && <meta name="twitter:image" content={head.image} />}
    </Helmet>
  )
}
export default HEAD
HEAD.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}
HEAD.defaultProps = {
  title: null,
  description: null,
  keywords: null,
  image: null,
  article: false,
}
const query = graphql`
  query HEAD {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        defaultKeywords: keywords
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

