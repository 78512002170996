import React from 'react'
import styled from 'styled-components'
import Wave from './Wave';

const SectionGroupnew = styled.div`
    background: url(${props => props.image});
    height: 100%;
    background-size: cover;
    background-position:bottom;
    display: block;
    position: relative;
    margin:0;
    padding:0;

    @media (max-width: 640px) {
        height: 100%;

    }
`

const SectionLogonew = styled.img`
    align-self: end;
    width: 128px;
    margin: 0 auto;
`

const SectionTitleGroupnew = styled.div`
    display: block;
   padding:190px 0px;

    @media (max-width: 720px) {
    }
`

const SectionTitlenew = styled.h3`
    color: white;
    font-size: 40px;
    margin: 0;
    text-transform:uppercase;

    line-height: 1.2;
    text-align:center;
background: -webkit-gradient(linear, left top, right top, from #ffcc00, to #ff2a2a);
        background: linear-gradient(to right, #ffcc00 0%, #ff2a2a 100%);
        
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

    @media (max-width: 720px) {
        font-size: 20px;
        text-transform:uppercase;
    }
`

const SectionTextnew = styled.p`
    line-height: 1.2;
    margin-top:10px;
    text-align:center;
background: -webkit-gradient(linear, left top, right top, from(rgb(245, 161, 36)), to(rgb(216, 43, 99)));
        background: linear-gradient(to right, rgb(245, 161, 36) 0%, rgb(216, 43, 99) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size:20px;
        font-weight:600;
      padding:0;
     
`

const WaveBottomnew = styled.div`
    position: absolute;
    width: 100%;
    bottom: -9px;
    display:block;

`

const WaveTopnew = styled.div`
    position: absolute;
    width: 100%;
    top: -9px;
    transform: rotate(180deg);
    display:block;
`

const Sectionnew = props => (
	<SectionGroupnew image={props.image}>
        <WaveTopnew><Wave /></WaveTopnew>
        <WaveBottomnew><Wave /></WaveBottomnew>
		<SectionTitleGroupnew>
			<SectionTitlenew>{props.title}</SectionTitlenew>
			<SectionTextnew>{props.text}</SectionTextnew>
		</SectionTitleGroupnew>
	</SectionGroupnew>
)

export default Sectionnew