// Navbar.js
import Link from 'gatsby-link'

import React, { useState } from "react"
import styled from "styled-components"
import NavbarLinks from "./NavbarLinks"
import DarkModeSwitch from '../switch.js'




const Navigation = styled.nav`
position:fixed;
width: 100%;
margin:0 auto;
padding: 15px 0;
z-index: 100;
transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
background-color: #000000;

  @media (max-width: 768px) {
    position: fixed;
    width: 100%;
    padding: 10px 0;
    z-index: 100;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    background-color: #000000;

  }
`

const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  

  @media (max-width: 768px) {
    position relative;
    display:flex;
    padding-top:6px;

  }
`
const HeaderArea = styled.div `
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(3, auto);
        align-items: center;
        justify-items: center;
        align-items: center;
        max-width:1200px;
`

const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    background-color: #000;
    transition: all 0.3s ease-in;
    top: 7vh;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    left: ${props => (props.open ? "-100%" : "0")};
  }
`

const Hamburger = styled.div`
  background-color: #ffffff;
  width: 20px;
  height: 2px;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 20px;
    height: 2px;
    background-color: #ffffff;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 0px;
  }
`
const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
   
    <Navigation>

<HeaderArea>
<Link className="l" to="/"><img src='/images/logo-timas.svg' width="220" /></Link>

      <Toggle
        navbarOpen={navbarOpen}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>

      {navbarOpen ? (
        <Navbox>
          <NavbarLinks />
        </Navbox>
      ) : (
        <Navbox open>
          <NavbarLinks />
        </Navbox>
        
      )}
       <Link className="n" to="/"><img src='/images/logo-timas.svg' width="200" /></Link>
                    <DarkModeSwitch></DarkModeSwitch>
                   

                    </HeaderArea>
    </Navigation>
  
  )
}

export default Navbar