import React from 'react'
import DarkModeSwitch from './switch.js'

const Wave = () => (
    <svg  width="100%" height="200" z-index="-1" border="0" fill="none" xmlns="http://www.w3.org/2000/svg">
       
        <path className="svgt"  >
        <animate  repeatCount="indefinite" fill="none" attributeName="d" dur="10s" values="M0 77 
            C 473,283
              822,-40
              1920,116 
            
            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 473,-40
              1222,283
              1920,136 
            
            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 973,260
              1722,-53
              1920,120 
            
            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 473,283
              822,-40
              1920,116 
            
            V 359 
            H 0 
            V 67 
            Z
        "
        />
        </path>
    </svg>
)

export default Wave