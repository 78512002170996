import React from 'react'
import './Testimonial.css'

const Testimonial = props => (
    
         <div className="TestimonialGroup">
        <img src={props.image} />
        
        <div className="TextWrapper"> <div className="TestimonialText"><h3>{props.title}</h3></div>
        <div><h4>{props.author}</h4></div><div><p>{props.text}</p></div></div>
    </div>
)

export default Testimonial