import React from 'react'
import styled from 'styled-components'
import Wave from './Wave';
import { Link } from 'gatsby';

const SectionContent = styled.div`
display:grid;
height: 500px;
margin-top:80px;


`

const SectionGroup = styled.div`
    background:  url(${props => props.image});
    height: 750px;
    background-size: cover;
    display: grid;
    grid-gap: 20px;
    position: relative;
    

    @media (max-width: 640px) {
        height: 750px;
        display:grid;
        grid-gap: 0px;
        background-position:center;
    }
`

const SectionLogo = styled.img`
    align-self: end;
    width: 170px;
    margin: 0 auto;
    margin-top:80px;
`



const SectionTitle = styled.h3`
    color: white;
    font-size: 40px;
    margin: 0;
    text-transform:uppercase;
    letter-spacing:2px;
    line-height: 1.2;
    margin: 0 auto;
    margin-top:20px;
    margin-bottom:0px;
    text-align:center;
    @media (max-width: 720px) {
        font-size: 20px;
        text-align:center;
        text-transform:uppercase;
        letter-spacing:2px;
        background: -webkit-gradient(linear, left top, right top, from #ffcc00), to #ff2a2a));
        background: linear-gradient(to right, #ffcc00 0%, #ff2a2a 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`

const SectionText = styled.p`
    color: white;
    margin: 0 auto;
    text-align:center;
    max-width:600px;
    @media (max-width: 720px) {
        text-align:center;
        font-size:16px;
        margin-top:0px;
        margin: 0 30px;
        line-height:1.8;
        letter-spacing: -0.03em;
    }
`
 

const SectionButton = styled.button`
padding: 8px 20px;
font-size: 16px;
background: -webkit-gradient(linear, left top, right top, from #ffcc00), to #ff2a2a));
background: linear-gradient(to right, #ffcc00 0%, #ff2a2a 100%);
border: none;
font-weight: 700;
text-transform:uppercase;
letter-spacing:2px;
border-radius: 10px;
outline: none;
cursor: pointer;
transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
color:#f1f1f1;
}
`

const WaveBottom = styled.div`
    position: absolute;
    width: 100%;
    bottom: -9px;
`

const WaveTop = styled.div`
    position: absolute;
    width: 100%;
    top: -9px;
    border:none;
    transform: rotate(180deg);
`

const Section = props => (
	<SectionGroup image={props.image}>
         
        <WaveTop><Wave /></WaveTop>
        <SectionContent><SectionLogo   src={props.logo} />
        <SectionTitle>{props.title}</SectionTitle>
			<SectionText>{props.text}</SectionText>
           <Link className="middle" to="/contact"><SectionButton>{props.button}</SectionButton></Link> </SectionContent>
        
        <WaveBottom><Wave /></WaveBottom>
		

	
        
	</SectionGroup>
)

export default Section